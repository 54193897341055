import { Input, Label } from "reactstrap"
import { wrapRootElement } from "./wrap-with-provider"

// export { wrapRootElement } from "./wrap-with-provider"
import React, { useLayoutEffect, useState, useEffect } from "react"
import Cookies from "js-cookie"
import posthog from "posthog-js"
import { PostHogProvider, usePostHog } from "posthog-js/react"
import { use } from "react"
// import { CartOpenContextProvider } from "./src/components/context/cartOpenContext"
// import { ReferrerContextProvider } from "./src/components/context/referrerContext"

const WrapElement = ({ element, props }) => {
  const [open, setOpen] = useState(false)
  const [cookieState, setCookieState] = useState({
    marketing: true,
    statistics: true,
  })
  useLayoutEffect(() => {
    const cookie = Cookies.get("cookie-settings")

    if (cookie) {
      updateCookies(JSON.parse(cookie))
      setCookieState(JSON.parse(cookie))
    } else if (!window?.location?.href?.includes("/survey")) {
      setOpen(true)
    }
  }, [])

  useEffect(() => {
    let posthogOptions = {
      api_host: process.env.GATSBY_PUBLIC_POSTHOG_HOST,
      //  "https://localhost:8888/ingest",

      person_profiles: "identified_only",
      persistence: "memory",
      capture_pageview: false,
      loaded: (posthog) => {
        posthog.capture("$pageview")

        if (process.env.NODE_ENV === "development") posthog.debug()
      },
    }
    const cookieRaw = Cookies.get("cookie-settings")
    const cookie = cookieRaw ? JSON.parse(cookieRaw) : null

    if (cookie && cookie.statistics) {
      posthogOptions.persistence = "localStorage+cookie"
    }
    // if (!window.posthog) {
    // if (process.env.NODE_ENV != "development") {
    posthog.init(process.env.GATSBY_PUBLIC_POSTHOG_KEY, posthogOptions)
    // }
    // }
  }, [])

  const updateCookies = ({ marketing, statistics }) => {
    if (marketing) {
      window?.dataLayer?.push({
        event: "marketingOptIn",
        dlMarketingConsent: true,
      })
    }
    if (statistics) {
      window?.dataLayer?.push({
        event: "statisticsOptIn",
        dlStatisticsConsent: true,
      })
      // if (!window.posthog) {
      posthog.set_config({ persistence: "localStorage+cookie" })
      // }
    }

    Cookies.set(
      "cookie-settings",
      JSON.stringify({ marketing: marketing, statistics: statistics }),
      {
        secure: true,
        expires: 365,
        domain:
          process.env.NODE_ENV === "development" ? "localhost" : ".liavo.de",
      }
    )
  }

  return (
    <>
      <PostHogProvider client={posthog}>
        <PageUpdate location={props.location}>{element}</PageUpdate>
      </PostHogProvider>
      {open && (
        <div
          style={{
            position: "sticky",
            width: "100%",
            maxWidth: 1000,
            // height: 200,
            backgroundColor: "white",
            borderRadius: 10,
            bottom: 20,
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}
          className="shadow row border border-primary"
        >
          <div className="d-flex flex-column p-2 col-12 col-md-8">
            <h5>Datenschutz</h5>
            <p style={{ fontSize: "12px" }}>
              Wir nutzen Cookies auf unserer Website. Einige von ihnen sind
              essenziell, während andere uns helfen, diese Website und Ihre
              Erfahrung zu verbessern. Wenn Sie unter 16 Jahre alt sind und Ihre
              Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre
              Erziehungsberechtigten um Erlaubnis bitten. Personenbezogene Daten
              können verarbeitet werden (z. B. IP-Adressen), z. B. für
              personalisierte Anzeigen und Inhalte oder Anzeigen- und
              Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer
              Daten finden Sie in unserer Datenschutzerklärung. Sie können Ihre
              Auswahl jederzeit unter Einstellungen widerrufen oder anpassen.
            </p>
            <div className="d-flex " style={{ gap: "20px" }}>
              <div className="ms-3">
                <Input
                  type="switch"
                  role="switch"
                  checked={true}
                  disabled={true}
                />
                <Label className="ps-1">Funtional</Label>
              </div>
              <div>
                <Input
                  type="switch"
                  role="switch"
                  checked={cookieState.statistics}
                  onChange={() =>
                    setCookieState({
                      ...cookieState,
                      statistics: !cookieState.statistics,
                    })
                  }
                />{" "}
                <Label>Statistik</Label>
              </div>
              <div>
                <Input
                  type="switch"
                  role="switch"
                  checked={cookieState.marketing}
                  onChange={() =>
                    setCookieState({
                      ...cookieState,
                      marketing: !cookieState.marketing,
                    })
                  }
                />{" "}
                <Label>Marketing</Label>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column p-2 col-12 col-md-4">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                updateCookies({ marketing: true, statistics: true })
                setOpen(false)
              }}
            >
              Alle akzeptieren
            </button>
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                updateCookies({ marketing: false, statistics: false })
                setOpen(false)
              }}
            >
              Alle ablehnen
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                updateCookies({
                  marketing: cookieState.marketing,
                  statistics: cookieState.statistics,
                })
                setOpen(false)
              }}
            >
              Individuell speichern
            </button>
          </div>
        </div>
      )}
    </>
  )
}

const wrapPageElement = ({ element, props }) => {
  return <WrapElement element={element} props={props} />
}

export { wrapPageElement, wrapRootElement }

const PageUpdate = ({ children, location }) => {
  const posthog = usePostHog()

  useEffect(() => {
    posthog.capture("$pageview")
  }, [location?.pathname])

  return children
}
